var selectedInput = null;
$(function(){
    
    $('input, textarea, select').focus(function(){
        selectedInput = this;
    }).blur(function(){
        selectedInput = null;
    });

    $.cookieBar({
        fixed: true,
        bottom: true,
        zindex: 2000,
        message: 'ERIT gebruikt cookies voor een optimale gebruikservaring.',
        acceptText: 'Akkoord'
    });

    $('.patient-select').selectize({
        valueField: 'id',
        labelField: 'search',
        searchField: ['search'],
        maxItems: 1,
        create: function(input) {
            $.ajax({
                url: '/patients/add-patient',
                type: 'POST',
                dataType: 'json',
                data: {
                    name: input
                },
                beforeSend: function(){
                    $('#modalNoticeText').html("Pati&euml;nt aanmaken ...");
                    $('#modalNotice').modal('show');
                },
                success: function(res){
                    window.location = '/main/set';
                }
            });
            return {
                value: input,
                text: input
            }
        },
        load: function(query, callback){
            if(!query.length) return callback();
            $.ajax({
                url: '/patients/get-patients',
                type: 'GET',
                dataType: 'json',
                data: {
                    q: query
                },
                beforeSend: function(){
                    $('#patient-spinner').spin('small');
                },
                success: function(res){
                    callback(res.patients);
                },              
                complete: function(res){
                    $('#patient-spinner').spin(false);
                }
            });
        },
        onChange: function(value){
            $.ajax({
                url: '/patients/set-patient',
                type: 'POST',
                dataType: 'json',
                data: {
                    patientID: value[0]
                },
                beforeSend: function(){
                    $('#modalNoticeText').html("Pati&euml;nt laden ...");
                    $('#modalNotice').modal('show');
                },
                success: function(res){
                    $('#modalNotice').modal('hide');
                    if(res.status == "ok") window.location = '/main/set';
                    else alert(res.error);
                }  
            });
        }       
    });

    var $input = $('.datepicker').pickadate({
        firstDay: 1,
    });
    var treatmentDatePicker = $input.pickadate('picker');

    $('.treatment-select').on('click', function(e){
        e.preventDefault();
        if($(this).attr('id').indexOf('treatmentSelectButton2-') > -1){
            var treatmentID = $(this).attr('id').replace('treatmentSelectButton2-', '');
        }
        else {
            var treatmentID = $(this).attr('id').replace('treatmentSelectButton-', '');
        }
        $.ajax({
            url: '/patients/set-treatment',
            type: 'POST',
            dataType: 'json',
            data: {
                treatmentID: treatmentID
            },
            beforeSend: function(){
                $('#treatmentModal').modal('hide');
                $('#modalNoticeText').html("Behandeling laden ...");
                $('#modalNotice').modal('show');
            },
            success: function(res){
                if(res.status == "ok"){
                    window.location = window.location.href.replace('main/set', 'anamnese');
                }
                else{
                    alert(res.error);
                }
            }  
        });
    });

    $('#newTreatment').on('click', function(e){
        e.preventDefault();
        $.ajax({
            url: '/patients/add-treatment',
            type: 'POST',
            dataType: 'json',
            data: {
                treatmentDate: treatmentDatePicker.get("select", "yyyy-mm-dd")
            },
            beforeSend: function(){
                $('#treatmentModal').modal('hide');
                $('#modalNoticeText').html("Behandeling aanmaken ...");
                $('#modalNotice').modal('show');
            },
            success: function(res){
                if(res.status == "ok"){
                    window.location = '/anamnese';
                }
                else{
                    alert(res.error);
                }
            }
        });
    });

    $('#togglePatientForm').on("click", function(e){
        e.preventDefault();
        $('.patientDivName').toggle();
        $('.patientDivForm').toggle();
        $('.deleteDropdown').toggle();
        $('.treatmentDropdown').toggle();
        $('.patientDiv').removeClass('has-error');
        var inputPatient = $('.selectize-input').find('input');
        inputPatient.click();
        inputPatient.focus();
    });

     $('#patientButtonDelete').on('click', function(e){
        e.preventDefault();
        $('#modalConfirmTitle').html("Pati&euml;nt verwijderen");
        $('#modalConfirmText').html("Ben je zeker dat je deze pati&euml;nt wilt verwijderen?");
        $('#modalConfirmButton').html('Verwijderen');
        $('#modalConfirm').modal('show');
        $('#modalConfirmButton').off('click');
        $('#modalConfirmButton').on('click', function(){
            $.ajax({
                url: '/patients/delete-patient',
                type: 'POST',
                dataType: 'json',
                data: {
                },
                beforeSend: function(){
                    $('#modalConfirm').modal('hide');
                    $('#modalNoticeText').html("Pati&euml;nt verwijderen ...");
                    $('#modalNotice').modal('show');
                },
                success: function(res){
                    if(res.status == "ok"){
                        window.location = '/main';
                    }
                    else {
                        alert(res.error);
                    }
                }
            });
        });
    });
    $('#treatmentButtonDelete').on('click', function(e){
        e.preventDefault();
        $('#modalConfirmTitle').html("Behandeling verwijderen");
        $('#modalConfirmText').html("Ben je zeker dat je deze behandeling wilt verwijderen?");
        $('#modalConfirmButton').html('Verwijderen');
        $('#modalConfirm').modal('show');
        $('#modalConfirmButton').off('click');
        $('#modalConfirmButton').on('click', function(){
            $.ajax({
                url: '/patients/delete-treatment',
                type: 'POST',
                dataType: 'json',
                data: {
                },
                beforeSend: function(){
                    $('#modalConfirm').modal('hide');
                    $('#modalNoticeText').html("Behandeling verwijderen ...");
                    $('#modalNotice').modal('show');
                },
                success: function(res){
                    if(res.status == "ok"){
                        window.location = '/main';
                    }
                    else {
                        alert(res.error);
                    }
                }
            });
        });
    });

});

function myDrawLine(drawingName, color, fillColor, width, type, dash, groups, name, param1, param2, param3, param4, param5, param6, setActive, canvasID, drawingTitle, clickable, canvasSize){
    if(type == "stroke"){
        $('#' + canvasID).drawLine({
            layer: true,
            groups: [groups],
            name: name,
            strokeStyle: color,
            strokeWidth: width,
            strokeDash: (dash != 0) ? [5] : null,
            x1: param1, y1: param2,
            x2: param3, y2: param4
        });
        if(clickable){
            $('#' + canvasID).drawRect({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 1,
                fromCenter: true,
                x: (parseInt(param1)+parseInt(param3))/2, y: (parseInt(param2)+parseInt(param4))/2,
                width: Math.sqrt((param3-param1)*(param3-param1)+(param4-param2)*(param4-param2)),
                height: 25,
                rotate: Math.atan2(param4-param2, param3-param1)*180/Math.PI,
                cursors: {
                    mouseover: 'pointer'
                },
                click: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                mouseup: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                touchstart: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                        setText(drawingTitle, drawingName, canvasID, canvasSize);
                    }
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
        else if(drawingName){
            $('#' + canvasID).drawRect({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 1,
                fromCenter: true,
                x: (parseInt(param1)+parseInt(param3))/2, y: (parseInt(param2)+parseInt(param4))/2,
                width: Math.sqrt((param3-param1)*(param3-param1)+(param4-param2)*(param4-param2)),
                height: 25,
                rotate: Math.atan2(param4-param2, param3-param1)*180/Math.PI,
                cursors: {
                    mouseover: 'pointer'
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                touchstart: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }       
    }
    else if(type == "circle"){
        $('#' + canvasID).drawEllipse({
            layer: true,
            groups: [groups],
            name: name,
            strokeStyle: color,
            strokeWidth: width,
            fillStyle: fillColor,
            strokeDash: (dash != 0) ? [5] : null,
            x: param1, y: param2,
            width: param3, height: param4
        });
        if(clickable){
            $('#' + canvasID).drawEllipse({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 8,
                x: param1, y: param2,
                width: param3, height: param4,
                cursors: {
                    mouseover: 'pointer'
                },
                click: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                mouseup: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                touchstart: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                        setText(drawingTitle, drawingName, canvasID, canvasSize);
                    }
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
        else if(drawingName){
            $('#' + canvasID).drawEllipse({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 8,
                x: param1, y: param2,
                width: param3, height: param4,
                cursors: {
                    mouseover: 'pointer'
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                touchstart: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }   
    }
    else if(type == "triangle"){
        $('#' + canvasID).drawPolygon({
            layer: true,
            groups: [groups],
            name: name,
            strokeWidth: width,
            fillStyle: fillColor,
            strokeStyle: color,
            strokeDash: (dash != 0) ? [5] : null,
            x: param1, y: param2,
            radius: param3, rotate: param4,
            sides: 3
        });
        if(clickable){
            $('#' + canvasID).drawPolygon({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: width,
                fillStyle: 'transparent',
                x: param1, y: param2,
                radius: param3*3, rotate: param4,
                sides: 3,
                cursors: {
                    mouseover: 'pointer'
                },
                click: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                mouseup: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                touchstart: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                        setText(drawingTitle, drawingName, canvasID, canvasSize);
                    }
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
        else if(drawingName){
            $('#' + canvasID).drawPolygon({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 25,
                x: param1, y: param2,
                radius: param3, rotate: param4,
                sides: 3,
                cursors: {
                    mouseover: 'pointer'
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                touchstart: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }   
    }
    else if(type == "arc"){
        $('#' + canvasID).drawArc({
            layer: true,
            groups: [groups],
            name: name,
            strokeWidth: width,
            fillStyle: 'transparent',
            strokeStyle: color,
            strokeDash: (dash != 0) ? [5] : null,
            x: param1, y: param2,
            radius: param3, start: param4,
            end: param5
        });
        if(clickable){
            $('#' + canvasID).drawArc({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 25,
                x: param1, y: param2,
                radius: param3, start: param4,
                end: param5,
                cursors: {
                    mouseover: 'pointer'
                },
                click: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                mouseup: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                touchstart: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                        setText(drawingTitle, drawingName, canvasID, canvasSize);
                    }
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
        else if(drawingName){
            $('#' + canvasID).drawArc({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 25,
                x: param1, y: param2,
                radius: param3, start: param4,
                end: param5,
                cursors: {
                    mouseover: 'pointer'
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                touchstart: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }   
    }
    else if(type == "quadratic"){
        $('#' + canvasID).drawQuadratic({
            layer: true,
            groups: [groups],
            name: name,
            strokeWidth: width,
            fillStyle: fillColor,
            strokeStyle: color,
            strokeDash: (dash != 0) ? [5] : null,
            x1: param1, y1: param2,
            cx1: param3, cy1: param4,
            x2: param5, y2: param6
        });
        if(clickable){
            $('#' + canvasID).drawArc({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 25,
                x1: param1, y1: param2,
                cx1: param3, cy1: param4,
                x2: param5, y2: param6,
                cursors: {
                    mouseover: 'pointer'
                },
                click: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                mouseup: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                touchstart: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                        setText(drawingTitle, drawingName, canvasID, canvasSize);
                    }
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
        else if(drawingName){
            $('#' + canvasID).drawArc({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 25,
                x1: param1, y1: param2,
                cx1: param3, cy1: param4,
                x2: param5, y2: param6,
                cursors: {
                    mouseover: 'pointer'
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                touchstart: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }   
    }
    else if(type == "image"){
        $('#' + canvasID).drawImage({
            layer: true,
            groups: [groups],
            name: name,
            scale: 0.4,
            source: "/img/" + param3 + ".png",
            x: param1, y: param2,
            cursors: {
                mouseover: 'pointer'
            },
            mouseover: function(){
                setText(drawingTitle, drawingName, canvasID, canvasSize);
            },
            touchstart: function(){
                setText(drawingTitle, drawingName, canvasID, canvasSize);
            },
            mouseout: function(){
                deleteText(canvasID);
            }
        });
    }
    else if(type == "text"){
        if(canvasSize == "small" && param3 != "L" && param3 != "R"){
            textFontSize = 6;
            textStrokeWidth = 0.1;
        }
        else{
            textFontSize = 12;
            textStrokeWidth = 0.7;
        }
        $('#' + canvasID).drawText({
            layer: true,
            groups: [groups],
            name: name,
            strokeWidth: textStrokeWidth,
            strokeStyle: color,
            fillStyle: color,
            x: param1, y: param2,
            text: param3.replace("\\n", "\n"),
            fontSize: textFontSize,
            fontFamily: 'Verdana, sans-serif'
        });
        if(clickable){
            var flag = false;
            $('#' + canvasID).drawText({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 6,
                fillStyle: 'transparent',
                x: param1, y: param2,
                text: param3,
                fontSize: textFontSize,
                fontFamily: 'Verdana, sans-serif',
                cursors: {
                    mouseover: 'pointer'
                },
                click: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                mouseup: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                    }
                },
                touchstart: function(){
                    if(!flag){
                        flag = true;
                        setTimeout(function(){ flag = false; }, 250);
                        saveDrawing(drawingName);
                        toggleDrawing(drawingName, canvasID);
                        setText(drawingTitle, drawingName, canvasID, canvasSize);
                    }
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
        else if(drawingName){
            $('#' + canvasID).drawText({
                layer: true,
                strokeStyle: 'transparent',
                strokeWidth: 8,
                fillStyle: 'transparent',
                x: param1, y: param2,
                text: param3,
                fontsize: 48,
                fontFamily: 'Verdana, sans-serif',
                cursors: {
                    mouseover: 'pointer'
                },
                mouseover: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                touchstart: function(){
                    setText(drawingTitle, drawingName, canvasID, canvasSize);
                },
                mouseout: function(){
                    deleteText(canvasID);
                }
            });
        }
    }
    if(setActive){
        setDrawingActive(drawingName, canvasID);
    }
}


function toggleDrawing(drawingName, canvasID){
    if($('#' + drawingName).hasClass('active')){
        setDrawingNonActive(drawingName, canvasID);
    }
    else {
        setDrawingActive(drawingName, canvasID);
    }
}

function setDrawingActive(drawingName, canvasID){
    $('#' + drawingName).removeClass('nonactive');
    $('#' + drawingName).addClass('active');
    $('#' + canvasID).setLayerGroup(drawingName + 'LayerGroup', {
        strokeStyle: '#f00',
    });
    $('#' + canvasID).drawLayers();
}

function setDrawingNonActive(drawingName, canvasID){
    $('#' + drawingName).removeClass('active');
    $('#' + drawingName).addClass('nonactive');
    $('#' + canvasID).setLayerGroup(drawingName + 'LayerGroup', {
        strokeStyle: '#aaa',
    });
    $('#' + canvasID).drawLayers();
}

function toggleChevron(e) {
    $(this).find("h3").toggleClass("accordion-closed accordion-open");
}

function setText(drawingTitle, drawingName, canvasID, canvasSize){
    $('#' + canvasID).removeLayer('drawingText');
    if(canvasSize == "small"){
        textX = 210;
        textY = 180;
        textMaxWidth = 200;
    }
    else if(canvasSize == "extra-large"){
        textX = 690;
        textY = 740;
        textMaxWidth = 680;
    }
    else{
        textX = 590;
        textY = 490;
        textMaxWidth = 580;
    }
    if(drawingTitle.indexOf("italic-") == -1){
        textFontStyle = "normal";
    }
    else{
        textFontStyle = "italic";
    }
    $('#' + canvasID).drawText({
        name: 'drawingText',
        layer: true,
        strokeStyle: '#3e7dbd',
        strokeWidth: 0.2,
        fillStyle: '#3e7dbd',
        fontStyle: textFontStyle,
        x: textX, y: textY,
        text: drawingTitle.replace("italic-", ""),
        align: 'right',
        respectAlign: true,
        fontSize: 12,
        fontFamily: 'Verdana, sans-serif',
        maxWidth: textMaxWidth
    });
}

function deleteText(canvasID){
    $('#' + canvasID).removeLayer('drawingText');
}

var spinNum = 0;
function saveDrawing(drawingName){
    $.ajax({
        url: '/drawings/save-drawing',
        type: 'POST',
        dataType: 'json',
        data: {
            drawing: drawingName,
            link: $('#' + drawingName).hasClass('active')
        },
        beforeSend: function(){
            $('#patient-spinner-name').spin('small');
            spinNum++;
        },
        success: function(res){
            if(res.status == "ok"){
                spinNum--;
                if(spinNum == 0) $('#patient-spinner-name').spin(false);
                if(res.alerts['alerts_red'].length != 0 || res.alerts['alerts_orange'].length != 0 ){
                    var modalHTML = "";
                    if(res.alerts['alerts_red'].length != 0){
                        $('#buttonAlerts').removeClass('btn-default').addClass('btn-danger');
                        for(var i = 0; i < res.alerts['alerts_red'].length; i++){
                            modalHTML += "<span class=\"color-danger\"><i class=\"icon fa fa-warning fa-lg\"></i>&nbsp;&nbsp;&nbsp;";
                            modalHTML += res.alerts['alerts_red'][i];
                            modalHTML += "</span><br><br>";
                        }
                    }
                    if(res.alerts['alerts_orange'].length != 0){
                        if(res.alerts['alerts_red'].length == 0) $('#buttonAlerts').removeClass('btn-default').removeClass('btn-danger').addClass('btn-warning');
                        for(var i = 0; i < res.alerts['alerts_orange'].length; i++){
                            modalHTML += "<span class=\"color-warning\"><i class=\"icon fa fa-warning fa-lg\"></i>&nbsp;&nbsp;&nbsp;";
                            modalHTML += res.alerts['alerts_orange'][i];
                            modalHTML += "</span><br><br>";
                        }
                    }
                    $('#buttonAlerts').attr("data-target", "#modalAlerts");
                    $('#modalAlertsText').html(modalHTML);
                }
                else{
                    $('#buttonAlerts').removeClass('btn-danger').removeClass('btn-warning').addClass('btn-default');
                    $('#buttonAlerts').attr("data-target", "");
                }
            }
            else {
              alert('Niet opgeslagen. Gelieve de patiënt opnieuw te laden.');
            }
        }             
    });
}